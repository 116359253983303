<template>
  <div class="home">
    <form
      id="app"
      @submit="onsubmit"
      method="get"
    >
      <input @blur="onLoss" @change="onLoss" class="tag-input" ref="tag_input" type="text" name="tag" id="tag-id" v-model="tag">

    </form>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  data () {
    return {
      tag: ''
    }
  },
  methods: {
    onLoss (e) {
      this.$refs.tag_input.focus()
    },
    onsubmit (e) {
      console.log(this.tag)
      fetch('https://bece-media-api.bece.com/api/nfc/' + this.tag)
        .then(response => response.json())
        .then(data => this.$router.push({ path: '/player', query: { tag: data.url } }))

      e.preventDefault()
    }
  },
  mounted () {
    this.$refs.tag_input.focus()
  }
}
</script>

<style>
    body {
      background-image: url("https://bece-media-api.bece.com/files/home.jpg");
      background-color: #cccccc;
    }
  .tag-input{
    height: 1px;
    width: 1px;
    border: 0px;
    caret-color: transparent;
  }

  .tag-input:focus {
    outline: none !important;
    caret-color: transparent;
  }
</style>
